
import {defineComponent} from "vue";

export default defineComponent({
  name: "ShareDialog",
  emits: ['onCloseWebShareModal'],
  setup(props, {emit}) {
    const url = location.href;

    const closeModal = (): void => {
      emit('onCloseWebShareModal')
    }
    return {
      closeModal,
      url
    }
  }
});
