import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4946a19f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("img", {
      alt: "Vue logo",
      src: _ctx.avatar,
      class: "profile-picture"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", null, _toDisplayString(_ctx.username), 1),
    _createElementVNode("h3", null, _toDisplayString(_ctx.tagline), 1)
  ]))
}