
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

const __default__ = defineComponent({
  name: "WhatsappButton",
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const store = useStore();

    return {
      whatsappLink: computed(() => `https://wa.me/${props.data.url.replaceAll(' ', '')}`),
      primaryColor: computed(() => store.state.primaryColor),
      secondaryColor: computed(() => store.state.secondaryColor)
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0e5ff09d": (_ctx.primaryColor),
  "62a8652a": (_ctx.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__