
import {computed, defineComponent} from "vue";

import YoutubePlayer from "@/components/YoutubePlayer.vue";
import SpotifyPlayer from "@/components/SpotifyPlayer.vue";
import ExternalLink from "@/components/ExternalLink.vue";
import HeaderBlock from "@/components/HeaderBlock.vue";
import Whatsapp from "@/components/WhatsappButton.vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "SectionItemList",
  components: {
    YoutubePlayer,
    SpotifyPlayer,
    ExternalLink,
    Whatsapp,
    HeaderBlock
  },
  setup() {
    const store = useStore();

    return {
      sections: computed(() => store.state.sections),
    }
  }
});
