import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93c31236"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "external-link",
    href: _ctx.data.url,
    target: "_blank"
  }, _toDisplayString(_ctx.data.text), 9, _hoisted_1))
}