import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-299706ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "social-network",
    key: _ctx.network.id
  }, [
    _createElementVNode("a", {
      href: _ctx.network.url,
      target: "_blank"
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(`fa-brands fa-${_ctx.network.network.toLowerCase()}`)
      }, null, 2)
    ], 8, _hoisted_1)
  ]))
}