
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import SocialNetwork from "@/components/SocialNetwork.vue";

export default defineComponent({
  name: "SocialNetworkList",
  components: {SocialNetwork},
  setup() {
    const store = useStore();

    return {
      socialNetworks: computed(() => store.state.socialNetworks),
    }
  }
})
