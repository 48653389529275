
import {computed, defineComponent} from 'vue';
import SocialNetworkList from "@/components/SocialNetworkList.vue";
import HeaderSection from "@/components/HeaderSection.vue";
import ShareButton from "@/components/ShareButton.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import SectionItemList from "@/components/SectionItemList.vue";
import {useHead, useSeoMeta} from "@vueuse/head";

const __default__ = defineComponent({
  name: "ProfileView",
  components: {
    SectionItemList,
    ShareButton,
    HeaderSection,
    SocialNetworkList,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const slug = route.params.username;
    store.commit('SET_SLUG', slug);
    store.dispatch("fetchData");

    const title =  computed(() => store.state.title ? store.state.title : store.state.username);
    const tagline = computed(() => store.state.tagline);
    const picture = computed(() => store.state.profile_picture)

    const ogTitle = computed(() => store.state.title + ' - DogLink');

    useHead({
      title: title,
      titleTemplate: (title?: string) => !title ? 'Default title' : `${title} - DogLink`,
      meta: [
        {
          name: "description",
          content: tagline
        }
      ]
    })

    useSeoMeta({
      ogDescription: tagline,
      ogTitle: ogTitle,
      ogImage: picture,
      twitterCard: 'summary',
    })

    return {
      title,
      tagline,
      avatar: computed(() => store.state.profile_picture),
      username: title,
      backgroundColor: computed(() => store.state.backgroundColor),
      textColor: computed(() => store.state.textColor),
      primaryColor: computed(() => store.state.primaryColor),
      secondaryColor: computed(() => store.state.secondaryColor),
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "23a5cd81": (_ctx.backgroundColor),
  "79aa75e2": (_ctx.textColor),
  "552f2b55": (_ctx.primaryColor),
  "c2d18bba": (_ctx.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__