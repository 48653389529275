
import {computed, defineComponent, ref, watch} from "vue";
import ShareDialog from "@/components/ShareDialog.vue";

export default defineComponent({
  name: "ShareButton",
  components: {ShareDialog},
  setup() {
    const webShareApiSupported = computed(() => navigator.share)
    const showShareDialog = ref(false)

    const openShareDialog = (value: boolean) => {
      showShareDialog.value = value;
    };

    const shareViaWebShare = () => {
      navigator.share({
        text: 'DogLink',
        url: location.href
      })
    }

    return {
      webShareApiSupported,
      shareViaWebShare,
      openShareDialog,
      showShareDialog
    }
  }
})
