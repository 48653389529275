
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";

const __default__ = defineComponent({
  name: 'App',

  setup() {
    const store = useStore();

    return {
      primaryColor: computed(() => store.state.primaryColor),
      secondaryColor: computed(() => store.state.secondaryColor),
    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "00921987": (_ctx.primaryColor),
  "06113856": (_ctx.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__