
import {defineComponent} from "vue";

export default defineComponent({
  name: "HeaderBlock",
  props: {
    data: {
      type: Object,
      required: true,
    }
  }
})
