
import {defineComponent} from "vue";

export default defineComponent({
  name: "HeaderSection",
  props: {
    avatar: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    tagline: {
      type: String,
      required: true,
      default: 'teste'
    }
  }
})
