
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "YoutubePlayer",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      videoHash: computed(() => {
        const params = new URL(props.data.url).searchParams.get('v');
        return `https://www.youtube.com/embed/${params}`;
      })
    }
  }
})
