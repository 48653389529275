import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "external-link-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (item) => {
      return (_openBlock(), _createBlock(_KeepAlive, {
        key: item.id
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(item.type), {
          data: item.data
        }, null, 8, ["data"]))
      ], 1024))
    }), 128))
  ]))
}